<template>
  <div class="search">
    <form
      id="search_form"
      class="d-flex justify-content-between align-items-center"
      @submit.prevent="performSearch($event)"
    >
      <input
        ref="searchBox"
        :placeholder="searchHint"
        title="Search"
        type="text"
        value=""
        class="searchBox"
        @focus="$emit('show-results')"
        @input="autocomplete"
        @click="autocomplete"
      />
      <input
        v-if="searchAutocomplete === 'false'"
        ref="searchButton"
        type="submit"
        class="searchSubmit btn rounded blue mt-0"
        value="Search"
        :disabled="noSearch"
      />
    </form>
    <div
      v-show="autocompleteTriggered === true"
      class="col-6 col-md-5 col-lg-4 justify-content-between"
      style="position: fixed; z-index: 999; height:calc(-163px + 100vh); max-height:none; left:6px;"
    >
      <div
        class="ac_table"
        style="height:calc(-163px + 100vh); max-height:none; "
      >
        <!-- Use v-show rather than v-if so attaching / detaching scroll bindings works even when "hidden". -->
        <div
          v-show="autocompleteNoResults"
          id="ac_no_results"
          class="ac_table"
        >
          <img
            src="../assets/search.svg"
            width="40"
            height="40"
            style="filter: brightness(0) saturate(100%) invert(66%) sepia(4%) saturate(6166%) hue-rotate(314deg) brightness(97%) contrast(97%);"
          /> <br>
          No search results found for {{ currentAutocompleteValue }}
        </div>
        <div
          v-show="autocompleteResultsError"
          id="ac_error_results"
          class="ac_table"
        >
          <img
            src="../assets/search.svg"
            width="40"
            height="40"
            style="filter: brightness(0) saturate(100%) invert(66%) sepia(4%) saturate(6166%) hue-rotate(314deg) brightness(97%) contrast(97%);"
          /> <br>
          <span style="color:red">
            Error communicating with the server, please try again.
          </span>
        </div>
        <div
          v-show="
            !autocompleteNoResults && !autocompleteResultsError && autocompleteTotals.owner !== 0
          "
          class="ac_table ac_results"
          :class="{
            ac_expanded_section: autocompleteSectionExpanded === 'owner'
          }"
          :style="{
            display: (
              (autocompleteExpandedClicked === true && (autocompleteSectionExpanded !== ('owner' || null)))
              || autocompleteTotals.owner === 0? 'none' : ''
            ),
            height: autocompleteHeights.owner,
          }"
        >
          <div>
            <div>
              <strong style="display:inline;text-align:left;">
                <span style="width:33%;float:left;">
                  <img
                    src="../assets/person.svg"
                    width="20"
                    height="20"
                    style="filter: brightness(0) saturate(100%) invert(79%) sepia(12%) saturate(1143%) hue-rotate(122deg) brightness(89%) contrast(89%);"
                  />
                  Owner
                </span>
                <span
                  style="width:33%;float:left;"
                >
                  {{ autocompleteTotals.owner }}
                </span>
                <span
                  style="width:33%;float:left;text-align:right;"
                ><a
                  href="#"
                  @click="expandSection('owner')"
                  v-text="((autocompleteSectionExpanded === 'owner') ? '< Back' : 'See All')"
                ></a></span>
              </strong><br>
              <span
                v-show="autocompleteInFlight"
              >&#9203;</span>
              <span
                v-show="
                  autocompleteTotals.owner !== 0
                "
                style="align:left;"
              >
                <span
                  v-if="autocompleteSectionExpanded !== 'owner'"
                >
                  <span
                    v-for="resultParts in autocompleteResults.owner"
                    :key="resultParts.result.robust_id"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="(resultParts.result.address ? resultParts.result.address.formatted : '')"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'owner'
                      )
                    "
                  >
                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
                <span
                  v-else
                  ref="loadMore"
                  style="overflow:scroll; height:100vh; width: 100%; display:flex; flex-direction:column;"
                >
                  <span
                    v-for="(resultParts, index) in autocompleteExpandedResults"
                    :key="index"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="(resultParts.result.address ? resultParts.result.address.formatted : '')"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'owner'
                      )
                    "
                  >

                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-show="
            !autocompleteNoResults && !autocompleteResultsError && autocompleteTotals.address !== 0
          "
          class="ac_table ac_results"
          :class="{
            ac_expanded_section: autocompleteSectionExpanded === 'address'
          }"
          :style="{
            display: (
              (autocompleteExpandedClicked === true && (autocompleteSectionExpanded !== ('address' || null)))
              || autocompleteTotals.address === 0 ? 'none' : ''
            ),
            height: autocompleteHeights.address,
          }"
        >
          <div>
            <div>
              <strong style="display:inline;text-align:left;">
                <span style="width:33%;float:left;">
                  <img
                    src="../assets/house.svg"
                    width="20"
                    height="20"
                    style="filter: brightness(0) saturate(100%) invert(66%) sepia(4%) saturate(6166%) hue-rotate(314deg) brightness(97%) contrast(97%);"
                  />
                  Address
                </span>
                <span
                  style="width:33%;float:left;"
                >
                  {{ autocompleteTotals.address }}
                </span>
                <span
                  style="width:33%;float:left;text-align:right;"
                ><a
                  href="#"
                  @click="expandSection('address')"
                  v-text="((autocompleteSectionExpanded === 'address') ? '< Back' : 'See All')"
                ></a></span>
              </strong><br>
              <span
                v-show="autocompleteInFlight"
              >&#9203;</span>
              <span
                v-show="
                  autocompleteTotals.address !== 0
                "
                style="align:left;"
              >
                <span
                  v-if="autocompleteSectionExpanded !== 'address'"
                >
                  <span
                    v-for="resultParts in autocompleteResults.address"
                    :key="resultParts.result.robust_id"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="resultParts.result.address.formatted"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'address'
                      )
                    "
                  >
                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
                <span
                  v-else
                  ref="loadMore"
                  style="overflow:scroll; height:100vh; display:block; width: 100%;"
                >
                  <span
                    v-for="(resultParts, index) in autocompleteExpandedResults"
                    :key="index"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="resultParts.result.address.formatted"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'address'
                      )
                    "
                  >

                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-show="
            !autocompleteNoResults && !autocompleteResultsError && autocompleteTotals.parcel !== 0
          "
          class="ac_table ac_results"
          :class="{
            ac_expanded_section: autocompleteSectionExpanded === 'parcel'
          }"
          :style="{
            display:(
              (autocompleteExpandedClicked === true && (autocompleteSectionExpanded !== ('parcel' || null)))
              || autocompleteTotals.parcel === 0? 'none' : ''
            ),
            height: autocompleteHeights.parcel,
          }"
        >
          <div>
            <span>
              <strong style="display:inline;text-align:left;">
                <span style="width:33%;float:left;">
                  <img
                    src="../assets/tag.svg"
                    width="20"
                    height="20"
                    style="filter: brightness(0) saturate(100%) invert(95%) sepia(10%) saturate(1880%) hue-rotate(322deg) brightness(102%) contrast(96%);"
                  />
                  Parcel Number
                </span>
                <span
                  style="width:33%;float:left;"
                >
                  {{ autocompleteTotals.parcel }}
                </span>
                <span
                  style="width:33%;float:left;text-align:right;"
                >
                  <a
                    href="#"
                    @click="expandSection('parcel')"
                    v-text="((autocompleteSectionExpanded === 'parcel') ? '< Back' : 'See All')"
                  ></a>
                </span>
              </strong><br>
              <span
                v-show="autocompleteInFlight"
              >&#9203;</span>
              <span
                v-show="
                  autocompleteTotals.parcel !== 0
                "
                style="align:left;"
              >
                <span
                  v-if="autocompleteSectionExpanded !== 'parcel'"
                >
                  <span
                    v-for="resultParts in autocompleteResults.parcel"
                    :key="resultParts.result.robust_id"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="(resultParts.result.address ? resultParts.result.address.formatted : '')"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'parcel'
                      )
                    "
                  >
                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
                <span
                  v-else
                  ref="loadMore"
                  style="overflow:scroll; height:100vh; display:block; width: 100%;"
                >
                  <span
                    v-for="(resultParts, index) in autocompleteExpandedResults"
                    :key="index"
                    class="autocomplete_item"
                    style="float:left;text-align: left;"
                    :data-address="(resultParts.result.address ? resultParts.result.address.formatted : '')"
                    :data-owner="resultParts.result.owner"
                    :data-parcel="resultParts.result.parcel_id"
                    :data-robust-id="resultParts.result.robust_id"
                    :data-county-id="resultParts.result.county.county_id"
                    @click="
                      goToResult(
                        resultParts.result.bounding_box,
                        resultParts.result.robust_id,
                        resultParts.result.parcel_id,
                        $event,
                        false,
                        true,
                        'parcel'
                      )
                    "
                  >

                    <span
                      v-for="(part, partIndex) in resultParts.adapted_subject_and_region_formatted"
                      :key="partIndex"
                      :style="{fontWeight:part['font_weight']}"
                      style="cursor: pointer;"
                    >{{ part['text'] }}</span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="recentSearchesTriggered === true"
      class="col-6 col-md-5 col-lg-4 justify-content-between"
      style="position: fixed;z-index: 999; height:calc(-163px + 100vh); max-height:none;  left:6px;"
    >
      <div
        class="rs_table"
        style="height:calc(-163px + 100vh); max-height:none; padding-top:16px; padding-left: 16px; position:relative;"
      >
        <div
          width="100%"
          style="display: flow-root"
        >
          <div style="float:left;">
            <img
              src="../assets/history.svg"
              width="20"
              height="20"
            />
            Recent
          </div>
          <div
            v-show="recentSearchHistory"
            style="float:right;"
          >
            <a
              v-b-modal="'modalClearHistory'"
              href="#"
            > Clear All</a>
          </div>
        </div>
        <div
          v-if="!recentSearchHistory"
          style="float:left"
        >
          <div
            class="rs_item example"
            style="color:#CCCCCC"
          >
            Start typing to search for an owner, address, or Parcel Identification number.
          </div>
          <div class="rs_item example">
            Examples<br>
          </div>
          <div class="rs_item example">
            <img
              src="../assets/house.svg"
              width="20"
              height="20"
            />
            123 Main Street City, State 12345
            <br>
          </div>
          <div class="rs_item example">
            <img
              src="../assets/tag.svg"
              width="20"
              height="20"
            />
            132-09281938
            <br>
          </div>
          <div class="rs_item example">
            <img
              src="../assets/person.svg"
              width="20"
              height="20"
            />
            Smith John H
            <br>
          </div>
        </div>
        <div
          v-else
        >
          <div
            v-for="(search, index) in recentSearchHistory"
            :key="index"
            class="rs_item"
          >
            <div
              class="rs_item_text"
              :owner="search.owner"
              :address="search.address"
              :value="search.searchValue"
              :parcel="search.parcelId"
              :county-id="search.countyID"
              @click="goToResult(
                {
                  minx: search.boundsMinX,
                  miny: search.boundsMinY,
                  maxx: search.boundsMaxX,
                  maxy: search.boundsMaxY,
                },
                search.robustId,
                search.parcelId,
                $event,
                true,
                false,
                'owner'
              )"
            >
              <img
                v-if="search.searchType === 'owner'"
                src="../assets/person.svg"
                width="20"
                height="20"
                style="cursor: pointer;"
              />
              <img
                v-else-if="search.searchType === 'address'"
                src="../assets/house.svg"
                width="20"
                height="20"
                style="cursor: pointer;"
              />
              <img
                v-else-if="search.searchType === 'parcel'"
                src="../assets/tag.svg"
                width="20"
                height="20"
                style="cursor: pointer;"
              />
              <span style="cursor: pointer;">
                {{ search.searchValue }}
              </span>
            </div>
            <div style="float:right">
              <img
                src="../assets/circle_x.svg"
                width="15"
                height="15"
                style="cursor: pointer;"
                @click="deleteSearch(search.searchValue)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BIND REAL DATA TO THE PARCEL DETAILS - INTEGRATION -->
    <div
      class="sr_parcelDetails"
    >
      <div class="sr_parcelDetails_row prev_next_parcel_row">
        <button
          id="back-to-search"
          class="btn blue"
          @click="backToSearch"
        >
          Back
        </button>
        <div class="d-flex justify-content-between align-items-center">
          <a
            class="cta-link parcel-link prev-parcel-link"
            @click="prevParcel"
          ><font-awesome-icon :icon="['fas', 'chevron-left']" /> Previous</a>
          <a
            class="cta-link parcel-link next-parcel-link"
            @click="nextParcel"
          >Next <font-awesome-icon
            :icon="['fas', 'chevron-right']"
          /></a>
        </div>
      </div>
      <div class="sr_parcelDetails_row fixed_owner_row text-left">
        <p>
          <strong>Owner</strong><br />
          <span class="details-owner">Smith John</span>
        </p>
        <p>
          <strong>Address</strong><br />
          <span class="details-address">123 Test Ave.</span>
        </p>
        <p>
          <strong>Parcel ID</strong><br />
          <span class="details-parcel">1234567890</span>
        </p>
      </div>
      <div class="sr_parcelDetails_row main_parcel_details text-left">
        <p>
          <strong>County</strong><br />
          Testing
        </p>
        <p>
          <strong>Municipality</strong><br />
          Testing
        </p>
        <p>
          <strong>Place</strong><br />
          Testing
        </p>
        <p>
          <strong>Transfer Date</strong><br />
          2020-09-15
        </p>
        <p>
          <strong>Sale Price</strong><br />
          $78,500.00
        </p>
        <p>
          <strong>Market Value (Total)</strong><br />
          $101,310.00
        </p>
        <p>
          <strong>Market Value (Land)</strong><br />
          $22,980.00
        </p>
        <p>
          <strong>Market Value (Building)</strong><br />
          $78,330.00
        </p>
        <p>
          <strong>Acreage</strong><br />
          0.85acres
        </p>
      </div>
      <div class="sr_parcelDetails_row county_link_row">
        <p>
          <a
            href="https://google.com"
            class="btn green"
            target="_blank"
          >County Link</a>
        </p>
      </div>
    </div>
    <div
      class="searchResults"
    >
      <table class="sr_header">
        <tr>
          <td
            :class="{ sr_sel: matchType === 'owner' }"
            @click="performSearch($event, 'owner')"
          >
            <div>Owner</div>
          </td>
          <td
            :class="{ sr_sel: matchType === 'address' }"
            @click="performSearch($event, 'address')"
          >
            <div>Address</div>
          </td>
          <td
            :class="{ sr_sel: matchType === 'parcel' }"
            @click="performSearch($event, 'parcel')"
          >
            <div>Parcel Number</div>
          </td>
        </tr>
      </table>
      <!-- Use v-show rather than v-if so attaching / detaching scroll bindings works even when "hidden". -->
      <div
        v-show="
          !firstPageSearchInFlight && !noResultsText && !searchResultsErrorText
        "
        ref="searchResults"
        class="sr_table"
      >
        <div
          v-for="result in searchResults"
          :key="result.result['robust_id']"
          class="sr_table_row"
          :owner="result.result['owner']"
          :address="(result.result.hasOwnProperty('address') ? result.result['address']['formatted'].toLowerCase() : '')"
          :parcel="result.result['parcel_id']"
          :data-parcel="result.result['parcel_id']"
          :data-bounds-minx="result.result['bounding_box']['min_x']"
          :data-bounds-miny="result.result['bounding_box']['min_y']"
          :data-bounds-maxx="result.result['bounding_box']['max_x']"
          :data-bounds-maxy="result.result['bounding_box']['max_y']"
          :data-robust-id="result.result['robust_id']"
          :data-county-id="result.result.county['county_id']"
          @click="
            goToResult(
              result.result['bounding_box'],
              result.result['robust_id'],
              result.result['parcel_id'],
              $event,
            )
          "
          @mouseover="hoverResult(result.result['bounding_box'])"
        >
          <div class="sr_table_result">
            <strong><span class="search-owner">{{ result.result["owner"] }}</span></strong><br />
            <span class="search-address">
              {{
                (result.result.hasOwnProperty('address') ? result.result['address']['formatted'].toLowerCase() : '')
              }}</span><br />
            {{ nullable(result.result["muni"]["muni_name"]).toLowerCase() }},
            {{ result.result["state"]["state_abbr"] }}<br />
            <span class="search-parcel">{{ result.result["parcel"] }}</span>
            <span class="search-address d-none">{{
              nullable(result.result["addr_number_start"]) +
                " " +
                nullable(result.result["addr_street_name"])
            }}</span>
            <!-- All result data currently avail from API can delete for prod
                          <div style="display: none;">{{ result }}</div>
                          -->
          </div>
        </div>
        <div
          v-if="nextPageSearchInFlight"
          class="text-center"
        >
          <BSpinner label="Loading next page..." />
        </div>
      </div>
      <div
        v-if="firstPageSearchInFlight"
        class="text-center pt-4"
      >
        <BSpinner label="Searching..." />
      </div>
      <div
        v-if="noResultsText"
        class="text-center pt-4"
      >
        {{ noResultsText }}
      </div>
      <div
        v-if="searchResultsErrorText"
        class="text-center pt-4 alert alert-danger"
        style="white-space: pre-wrap"
      >
        {{ searchResultsErrorText }}
      </div>
    </div>
    <BModal
      id="modalClearHistory"
      ref="hideModal"
      hide-footer
      hide-header-close
    >
      <div class="text-center">
        Are you sure you want to delete all your recently viewed searches?
      </div>
      <div class="text-center">
        <BButton
          class="btn green"
          style="margin: 14px;"
          @click="clearHistory"
        >
          Delete
        </BButton>
        <BButton
          class="btn grey2"
          style="margin: 14px;"
          @click="hideModal"
        >
          Close
        </BButton>
      </div>
    </BModal>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import axios from "axios";
import authHeader from "../store/services/auth-header";
import mapboxgl from "mapbox-gl";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp } from "firebase/app";
import "../scss/custom_styles/_search.scss";
import { BButton, BModal, BSpinner, VBModal } from "bootstrap-vue";
import Vue from "vue";
export default {
  name: "Search",
  analytics: null,
  components: {
    BSpinner,
    BModal,
  },
  directives: {
    directives: {
      "b-modal": VBModal,
    },
  },
  props: {
    resultsHidden: Boolean,
    selectedMap: {
      type: String,
      default: "mapbox",
    },
    autocompleteCleared: Boolean,
  },
  data() {
    return {
      searchHint: "Search for owner, address, or parcel",
      searchResults: [],
      matchType: "",
      currentSearchValue: null,
      currentSearchBoundsString: null,
      recentSearchesTriggered: false,
      recentSearchHistory: window.localStorage.getItem("searchHistory") ? this.sortSearchHistory(JSON.parse(window.localStorage.getItem("searchHistory"))) : "",
      searchRecentlyViewed: window.localStorage.getItem("search_recently_viewed"),
      firstPageSearchInFlight: false,
      nextPageSearchInFlight: false,
      searchResultsErrorText: null,
      searchPaging: 1,
      noSearch: true,
      noResultsText: null,
      initialSearchLoad: true,
      autocompleteSearch: false, // autocompleteSearch and recentSearch determine which panel to go back to
      recentSearch: false,
      searchAutocomplete: window.localStorage.getItem("search_autocomplete"),
      currentAutocompleteValue: null,
      autocompleteTriggered: false,
      autocompleteInFlight: false,
      autocompleteNoResults: false,
      autocompleteResultsError: false,
      autocompleteResults: {
        address: [],
        owner: [],
        parcel: [],
      },
      autocompleteTotals: {
        address: null,
        owner: null,
        parcel: null,
      },
      autocompleteHeights: {
        address: "31%",
        owner: "31%",
        parcel: "31%",
      },
      autocompleteSectionExpanded: null,
      autocompleteExpandedResults: [],
      autocompleteExpandedClicked: false,
      autocompleteExpandedPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      active: "active",
      loggedIn: "loggedIn",
    }),
  },
  watch: {
    autocompleteCleared(value) {
      if (value === true) {
        this.autocompleteSearch = false;
        this.autocompleteTriggered = false;
        this.recentSearchesTriggered = false;
        this.$emit("clear-autocomplete");
      }
    },
  },
  mounted() {
    const firebaseApp = getApp();
    this.$options.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    performSearch(eventParam, matchType = null) {
      if (!this.$refs.searchBox.value || this.searchAutocomplete != "false") {
        eventParam.preventDefault();
        return false;
      }
      this.autocompleteSearch = false;
      this.recentSearchHistory = false;
      this.autocompleteTriggered = false;
      this.recentSearchesTriggered = false;
      logEvent(this.$options.analytics, "search", {
        query: this.$refs.searchBox.value,
      });
      if (matchType !== null) {
        logEvent(this.$options.analytics, "search_restrict", {
          restrict_match: matchType,
        });
      }
      this.searchResultsErrorText = null;

      // Note: if null, then omit 'restrict_match_type' param from search, which will have endpoint choose
      // best (closest) match type for us.
      this.matchType = matchType;

      this.backToSearch();
      this.$emit("show-results");
      $(".searchResults .sr_table").unbind("scroll").scrollTop(0);

      // Only set currentSearchValue and currentBoundsString if this is a search performed from search box "Search"
      // button (i.e., when matchType is null). Subsequent (sub-)searches when an "Owner", "Address", or "Parcel"
      // button are clicked, will issue their search requests using these stored values and thus be consistent.
      if (matchType === null) {
        // Store contents of the search box for use by any subsequent sub-searches.
        // If we let sub-searches such as scroll for new page, or click on a "Owner", "Address", or "Parcel" button
        // for specific match type, simply use this.$refs.searchBox.value (value in text box), then if the user were
        // to change that text box value between this first search and subsequent sub-searches, the latters' results
        // would unintuitively be based on text box contents, and we would see inconsistent results.
        this.currentSearchValue = this.$refs.searchBox.value;
        const bounds = this.$store.state.mapBoundsArray;
        const boundsArray = [
          bounds[0][1],
          bounds[0][0],
          bounds[1][1],
          bounds[1][0],
        ];
        this.currentSearchBoundsString = boundsArray.join(",");
        this.matchType = "owner";
      }

      authHeader().then((headers) => {
        this.noResultsText = null;
        this.firstPageSearchInFlight = true;
        const params = {
          query: this.currentSearchValue,
          bounds: this.currentSearchBoundsString,
          results_per_page: 10,
          restrict_match_type: this.matchType,
          page: 1,
        };
        if (matchType !== null) {
          params["restrict_match_type"] = matchType;
        }
        axios
          .get(
            process.env.VUE_APP_SERVICE_BASE_URL +
                "/rest/landglide/map/v4/search",
            {
              headers: headers,
              params: params,
            },
          )
          .then((results) => {
            this.firstPageSearchInFlight = false;
            if (!results.data[this.matchType + "_results"].results) {
              logEvent(this.$options.analytics, "search_no_results");
              this.noResultsText = "No results found for";
              if (matchType !== null) {
                this.noResultsText += " " + matchType;
              }
              this.noResultsText +=
                  " search for '" + this.currentSearchValue + "'.";
              // Don't ask to try again if search was for a specific match type, as lack of results may be expected.
              if (matchType === null) {
                this.noResultsText += " Please try again.";
              }
              return false;
            }
            this.searchResults = results.data[this.matchType + "_results"].results;
            const searchResultsLength = this.searchResults.length;
            if (this.searchResults.length > 0) {
              this.$emit("show-results");
              let wait = false;
              this.searchPaging = 1;
              if (searchResultsLength >= 10
                  && this.$refs.searchResults.clientHeight >= this.$refs.searchResults.scrollHeight) {
                this.getMoreResults(this.searchPaging + 1);
                this.searchPaging++;
              }
              // console.log('performSearch binding getMoreResults fragment to sr_table');
              $(".searchResults .sr_table")
                .unbind("scroll")
                .on("scroll", (event) => {
                  if (
                    Math.ceil($(event.target).scrollTop() +
                          $(event.target).innerHeight()) >=
                      $(event.target)[0].scrollHeight
                  ) {
                    if (wait === false) {
                      wait = true;
                      if (searchResultsLength >= 10) {
                        if ( this.initialSearchLoad === false ||
                            (this.initialSearchLoad === true &&
                                window.innerHeight >= this.$refs.searchResults.scrollHeight)
                        ) {
                          this.getMoreResults(this.searchPaging + 1);
                        }
                        this.searchPaging++;
                        setTimeout(function() {
                          wait = false;
                        }, 600);
                      } else {
                        this.initialSearchLoad = false;
                        // console.log('end of results');
                      }
                    }
                  }
                });
            } else {
              logEvent(this.$options.analytics, "search_no_results");
              this.noResultsText = "No results found for";
              if (matchType !== null) {
                this.noResultsText += " " + matchType;
              }
              this.noResultsText +=
                    " search for '" + this.currentSearchValue + "'.";
              // Don't ask to try again if search was for a specific match type, as lack of results may be expected.
              if (matchType === null) {
                this.noResultsText += " Please try again.";
              }
            }
            this.initialSearchLoad = false;
          })
          .catch((searchError) => {
            // Hide spinner.
            this.firstPageSearchInFlight = false;

            // Reset search results state to default (initial empty) values.
            this.matchType = "owner";
            this.searchResults = [];
            this.currentSearchValue = null;
            this.currentSearchBoundsString = null;
            this.noResultsText = null;

            // Remove any scroll listener set by previous search results.
            $(".searchResults .sr_table").unbind("scroll");

            // Set error message for UI.
            this.searchResultsErrorText = "\u274C Search Error: ";
            // Ref: https://github.com/axios/axios#handling-errors
            if (searchError.response) {
              this.searchResultsErrorText +=
                    "Server response '" +
                    searchError.response.status +
                    " " +
                    searchError.response.statusText +
                    "'.";
            } else if (searchError.request) {
              this.searchResultsErrorText += "No response was received.";
            } else {
              this.searchResultsErrorText +=
                    "Unable to set up the request: " + searchError.message + ".";
            }
            this.searchResultsErrorText +=
                  "\nPlease contact support@landglide.com if this problem persists.";
          });
      });
    },
    async getMoreResults(page) {
      logEvent(this.$options.analytics, "search_fetch_next_page", {
        page: page,
      });
      this.$emit("show-results");
      authHeader().then((headers) => {
        this.nextPageSearchInFlight = true;
        axios
          .get(
            process.env.VUE_APP_SERVICE_BASE_URL +
                "/rest/landglide/map/v4/search",
            {
              headers: headers,
              params: {
                query: this.currentSearchValue,
                bounds: this.currentSearchBoundsString,
                restrict_match_type: this.matchType,
                results_per_page: 10,
                page: page,
              },
            },
          )
          .then((results) => {
            this.nextPageSearchInFlight = false;
            const pageResults = results.data[this.matchType + "_results"].results;
            this.searchResults = this.searchResults.concat(pageResults);
            if ((this.searchPaging * 10) <= results.data[this.matchType + "_results"].total_results
              && window.innerHeight >= this.$refs.searchResults.scrollHeight) {
              this.getMoreResults(this.searchPaging + 1);
              this.searchPaging++;
            }
          })
          .catch((searchError) => {
            this.nextPageSearchInFlight = false;

            // Reset search results state to default (initial empty) values.
            this.matchType = "";
            this.searchResults = [];
            this.currentSearchValue = null;
            this.currentSearchBoundsString = null;
            this.noResultsText = null;

            // Remove any scroll listener set by previous search results.
            $(".searchResults .sr_table").unbind("scroll");

            // Set error message for UI.
            this.searchResultsErrorText =
                  "\u274C Error searching for page " + JSON.stringify(page) + ": ";
            // Ref: https://github.com/axios/axios#handling-errors
            if (searchError.response) {
              this.searchResultsErrorText +=
                    "Server response '" +
                    searchError.response.status +
                    " " +
                    searchError.response.statusText +
                    "'.";
            } else if (searchError.request) {
              this.searchResultsErrorText += "No response was received.";
            } else {
              this.searchResultsErrorText +=
                    "Unable to set up the request: " + searchError.message + ".";
            }
            this.searchResultsErrorText +=
                  "\nPlease contact support@landglide.com if this problem persists.";
          });
      });
    },
    hoverResult(bounds) {
      // console.log('hover parcel');
      // HOVER OVER MATCHING PARCEL HERE
    },
    goToResult(bounds, robustid, parcelid, event, isRecentSearch = false, isAutocomplete = false, section = "") {
      let searchHistory = window.localStorage.getItem("searchHistory");
      let searchValue = event.currentTarget.getAttribute(this.matchType);
      // if using the recent searches or autocomplete, use the optional section variable.
      let matchType = (section !== "") ? section : this.matchType;
      let owner = event.currentTarget.getAttribute("owner");
      let address = event.currentTarget.getAttribute("address");
      let countyId = event.currentTarget.getAttribute("county-id");
      // if using autocomplete or recent searches, use the optional section value given in goToResult
      if (isAutocomplete === true) {
        this.autocompleteSearch = true;
        this.recentSearch = false;
        matchType = section;
        searchValue = event.currentTarget.getAttribute("data-" + matchType);
        owner = event.currentTarget.getAttribute("data-owner");
        address = event.currentTarget.getAttribute("data-address");
        countyId = event.currentTarget.getAttribute("data-county-id");
      } else if (isRecentSearch === true) {
        this.autocompleteSearch = false;
        this.recentSearch = true;
        this.recentSearchHistory = true;
        searchValue = event.currentTarget.getAttribute("value");
      } else {
        searchValue = event.currentTarget.getAttribute(matchType);
      }
      const searchLog = {
        searchType: matchType,
        searchValue: searchValue,
        parcelId: parcelid,
        boundsMinX: (bounds["minx"] ?? bounds["min_x"]),
        boundsMinY: (bounds["miny"] ?? bounds["min_y"]),
        boundsMaxX: (bounds["maxx"] ?? bounds["max_x"]),
        boundsMaxY: (bounds["maxy"] ?? bounds["max_y"]),
        robustId: robustid,
        owner: owner,
        address: address,
        countyID: countyId,
        time: Date.now(),
      };
      if (!searchHistory) {
        window.localStorage.setItem("searchHistory", JSON.stringify([searchLog]));
      } else {
        const newSearchHistory = JSON.parse(searchHistory);
        JSON.parse(searchHistory).find((search, i) => {
          if (search.searchValue === searchValue) {
            newSearchHistory.splice(i, 1);
          }
        });
        newSearchHistory.push(searchLog);
        searchHistory = newSearchHistory;
        window.localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
      }
      this.recentSearchHistory = window.localStorage.getItem("searchHistory") ? this.sortSearchHistory(JSON.parse(window.localStorage.getItem("searchHistory"))) : "";

      this.autocompleteTriggered = false;
      this.recentSearchesTriggered = false;
      let llb = "";
      if (!isRecentSearch) {
        bounds = {
          minx: bounds["min_x"],
          miny: bounds["min_y"],
          maxx: bounds["max_x"],
          maxy: bounds["max_y"],
        };
      }
      llb = this.sphereMercBoundsToLLBounds(bounds);

      logEvent(this.$options.analytics, "tapped_search_result");
      this.$emit("hideResults");
      this.$root.$emit("search-result-jump-to", llb);
      this.showDetails(parcelid, isAutocomplete, isRecentSearch);
      if (isRecentSearch) {
        this.showHistoryDetails({
          owner: event.currentTarget.getAttribute("owner"),
          address: event.currentTarget.getAttribute("address"),
        });
      }
      this.loadDetails(robustid);
    },
    sphereMercBoundsToLLBounds(smBounds) {
      const sw = this.sphereMercToLL(smBounds["minx"], smBounds["miny"]);
      const ne = this.sphereMercToLL(smBounds["maxx"], smBounds["maxy"]);
      return mapboxgl.LngLatBounds.convert([sw, ne]);
    },
    sphereMercToLL(x, y) {
      const longitude = (x / 20037508.34) * 180;
      const lat = (y / 20037508.34) * 180;
      const latitude = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180)) - Math.PI / 2);

      return {
        lng: longitude,
        lat: latitude,
      };
    },
    webMercBoundsToLLBounds(wmBounds) {
      const sw = this.webMercToLL(wmBounds["minx"], wmBounds["miny"]);
      const ne = this.webMercToLL(wmBounds["maxx"], wmBounds["maxy"]);
      return mapboxgl.LngLatBounds.convert([sw, ne]);
    },
    webMercToLL(x, y) {
      const R = 42722829.7235269770026207; // 128 * 2^20 / pi
      const lng = this.radiansToDegrees(x / R - Math.PI);
      const lat = this.radiansToDegrees(
        2 * Math.atan(Math.exp(Math.PI - y / R)) - Math.PI / 2,
      );
      return [lng, lat];
    },
    radiansToDegrees(r) {
      return r * (180 / Math.PI);
    },
    nullable(value) {
      return typeof value === "undefined" || value === null ? "" : value;
    },
    backToSearch() {
      this.autocompleteTriggered = (this.autocompleteSearch === true);
      this.recentSearchesTriggered = (this.recentSearch === true);
      // BACK TO SEARCH
      // console.log('back to search');
      $(".parcel-information").hide();
      $(".sr_parcelDetails").hide();
      if (this.autocompleteSearch === false) {
        $(".searchResults").show();
      }
    },
    prevParcel() {
      // PREVIOUS PARCEL DETAILS
      logEvent(this.$options.analytics, "go_to_previous_search_result");
      const currentparcelid = $(".sr_parcelDetails .details-parcel").text();
      const prevparcelid = $(
        ".sr_table_row[data-parcel=\"" + currentparcelid + "\"]",
      )
        .prev()
        .attr("data-parcel");
      const prevBounds = {
        minx: $(".sr_table_row[data-parcel=\"" + prevparcelid + "\"]").attr(
          "data-bounds-minx",
        ),
        miny: $(".sr_table_row[data-parcel=\"" + prevparcelid + "\"]").attr(
          "data-bounds-miny",
        ),
        maxx: $(".sr_table_row[data-parcel=\"" + prevparcelid + "\"]").attr(
          "data-bounds-maxx",
        ),
        maxy: $(".sr_table_row[data-parcel=\"" + prevparcelid + "\"]").attr(
          "data-bounds-maxy",
        ),
      };
      const prevrobustid = $(
        ".sr_table_row[data-parcel=\"" + prevparcelid + "\"]",
      ).attr("data-robust-id");
      const llbprev = this.webMercBoundsToLLBounds(prevBounds);
      this.$emit("hideResults");
      this.$root.$emit("search-result-jump-to", llbprev);
      this.showDetails(prevparcelid);
      this.loadDetails(prevrobustid);
    },
    nextParcel() {
      // NEXT PARCEL DETAILS
      logEvent(this.$options.analytics, "go_to_next_search_result");
      const currentparcelid = $(".sr_parcelDetails .details-parcel").text();
      const nextparcelid = $(
        ".sr_table_row[data-parcel=\"" + currentparcelid + "\"]",
      )
        .next()
        .attr("data-parcel");
      const nextBounds = {
        minx: $(".sr_table_row[data-parcel=\"" + nextparcelid + "\"]").attr(
          "data-bounds-minx",
        ),
        miny: $(".sr_table_row[data-parcel=\"" + nextparcelid + "\"]").attr(
          "data-bounds-miny",
        ),
        maxx: $(".sr_table_row[data-parcel=\"" + nextparcelid + "\"]").attr(
          "data-bounds-maxx",
        ),
        maxy: $(".sr_table_row[data-parcel=\"" + nextparcelid + "\"]").attr(
          "data-bounds-maxy",
        ),
      };
      const nextrobustid = $(
        ".sr_table_row[data-parcel=\"" + nextparcelid + "\"]",
      ).attr("data-robust-id");
      const llbnext = this.webMercBoundsToLLBounds(nextBounds);
      this.$emit("hideResults");
      this.$root.$emit("search-result-jump-to", llbnext);
      this.showDetails(nextparcelid);
      this.loadDetails(nextrobustid);
    },
    showDetails(parcelid, isAutocomplete = false, isRecentSearch = false) {
      this.autocompleteTriggered = false;
      this.recentSearchesTriggered = false;
      let owner;
      let address;
      let countyLink;
      if (isAutocomplete) {
        owner = $(
          ".autocomplete_item[data-parcel='" + parcelid + "']").attr("data-owner");
        address = $(
          ".autocomplete_item[data-parcel='" + parcelid + "']").attr("data-address");
        countyLink =
            "https://reportallusa.com/cama_redir?county_id=" +
            $(".autocomplete_item[data-parcel='" + parcelid + "']").attr(
              "data-county-id")
            + "&parcel_id=" +
            $(".autocomplete_item[data-parcel='" + parcelid + "']").attr(
              "data-parcel");
      } else if (isRecentSearch) {
        countyLink =
            "https://reportallusa.com/cama_redir?county_id=" +
            $(".rs_item_text[parcel='" + parcelid + "']").attr(
              "county-id") +
            "&parcel_id=" +
            $(".rs_item_text[parcel='" + parcelid + "']").attr(
              "parcel");
      } else {
        owner = $(
          ".sr_table_row[data-parcel='" + parcelid + "'] .search-owner")
          .text();
        address = $(
          ".sr_table_row[data-parcel='" + parcelid + "'] .search-address")
          .text();
        countyLink =
            "https://reportallusa.com/cama_redir?county_id=" +
            $(".sr_table_row[data-parcel='" + parcelid + "']").attr(
              "data-county-id") +f;
        "&parcel_id=" +
            $(".sr_table_row[data-parcel='" + parcelid + "']").attr(
              "data-parcel");
      }
      $(".sr_parcelDetails .details-owner").text(owner);
      $(".sr_parcelDetails .details-address").text(address);
      $(".sr_parcelDetails .details-parcel").text(parcelid);
      $(".sr_parcelDetails .county_link_row .btn").attr("href", countyLink);
      $(".searchResults").hide();
      $(".parcel-information").hide();
      $(".sr_parcelDetails").show();
      if (
        $(".sr_table_row[data-parcel='" + parcelid + "']").prev().length < 1
      ) {
        $(".prev-parcel-link").css("visibility", "hidden");
      } else {
        $(".prev-parcel-link").css("visibility", "visible");
      }
      if (
        $(".sr_table_row[data-parcel='" + parcelid + "']").next().length < 1
      ) {
        $(".next-parcel-link").css("visibility", "hidden");
      } else {
        $(".next-parcel-link").css("visibility", "visible");
      }
    },
    showHistoryDetails(values) {
      $(".sr_parcelDetails .details-owner").text(values.owner);
      $(".sr_parcelDetails .details-address").text(values.address);
    },
    loadDetails(robustid, isAutocomplete = false) {
      this.autocompleteTriggered = false;
      this.recentSearchesTriggered = false;
      $(".search .main_parcel_details").html("");
      this.detailsLoaded = false;
      authHeader().then((headers) => {
        axios
          .get(process.env.VUE_APP_TILE_BASE_URL + "/details/v1/" + robustid, {
            headers: headers,
          })
          .then((response) => {
            // console.log(response.data);
            $(".search .main_parcel_details").html(response.data);
          });
      });
    },
    autocomplete(e) {
      const inputVal = this.$refs.searchBox.value;
      this.noSearch = (inputVal.trim() === "");
      if (this.searchAutocomplete != "false") {
        let inFlightRequest = null;
        if (inFlightRequest) {
          inFlightRequest.abort();
          inFlightRequest = null;
        }

        if (inputVal.trim() && this.currentAutocompleteValue != this.$refs.searchBox.value) {
          this.autocompleteSectionExpanded = "";
          this.autocompleteExpandedClicked = false;
          this.recentSearchesTriggered = false;
          this.autocompleteTriggered = true;
          this.autocompleteSearch = true;
          this.recentSearch = false;
          this.currentAutocompleteValue = this.$refs.searchBox.value;
          let boundsVal = "0,0,0,0";
          const bounds = this.$store.state.mapBoundsArray;
          if (bounds) {
            boundsVal =
                bounds[0][1] + "," +
                bounds[0][0] + "," +
                bounds[1][1] + "," +
                bounds[1][0];
          }
          const inputVal = this.$refs.searchBox.value;
          // "proxy-to-kub01-30814.php/v1/autocomplete?query="
          // eslint-disable-next-line max-len
          const acUrl = process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/map/v4/autocomplete?query=" + encodeURIComponent(inputVal) + "&bounds=" + boundsVal + "&results_per_page=" + 30;
          // this.$options.auth.currentUser?.getIdToken();
          this.autocompleteInFlight = true;
          authHeader().then((headers) => {
            axios
              .get(
                acUrl,
                {
                  headers: headers,
                },
              )
              .then((data) => {
                let dataAsJSON;
                if (data.statusText !== "OK") {
                  this.autocompleteResultsError = true;
                } else {
                  if (data.data instanceof Object) {
                    dataAsJSON = data.data;
                  } else {
                    this.autocompleteResultsError = true;
                  }
                }
                if (dataAsJSON["status"] === "OK") {
                  const totalResults = dataAsJSON["address_results"].total_results +
                      dataAsJSON["owner_results"].total_results +
                      dataAsJSON["parcel_results"].total_results;
                  if (totalResults === 0) {
                    this.autocompleteNoResults = true;
                  } else {
                    this.autocompleteNoResults = false;
                    ["address", "owner", "parcel"].map((attribute) => {
                      if (typeof (dataAsJSON[attribute + "_results"]) !== "undefined") {
                        const attributeResults = dataAsJSON[attribute + "_results"];
                        const newHeight = Math.ceil(93 * attributeResults.total_results/totalResults);
                        // console.log(attribute + ": " + newHeight);
                        this.autocompleteHeights[attribute] = newHeight+"%";

                        if (attributeResults.total_results >= 1000000) {
                          attributeResults.total_results = Math.floor((attributeResults.total_results / 1000000)) + "M";
                        } else if (attributeResults.total_results >= 1000) {
                          attributeResults.total_results = Math.floor((attributeResults.total_results / 1000)) + "k";
                        }
                        Vue.set(this.autocompleteTotals, attribute, attributeResults.total_results);
                        Vue.set(this.autocompleteResults, attribute, attributeResults.results);
                      } else {
                        Vue.set(this.autocompleteTotals, attribute, 0);
                      }
                    });
                    this.autocompleteInFlight = false;
                  }
                }
              });
          });
        } else if (!inputVal.trim()) {
          this.autocompleteSectionExpanded = "";
          this.autocompleteExpandedClicked = false;
          this.currentAutocompleteValue = null;
          this.autocompleteTriggered = false;
          if ( this.searchRecentlyViewed !== "false" ) {
            this.recentSearchesTriggered = true;
            this.autocompleteSearch = false;
            this.recentSearch = false;
          }
          this.autocompleteResults = {
            address: [],
            owner: [],
            parcel: [],
          };
          this.autocompleteTotals = {
            address: null,
            owner: null,
            parcel: null,
          };
        }
      } else if (this.searchRecentlyViewed !== "false") {
        this.recentSearchesTriggered = true;
      }
    },
    clearHistory() {
      this.recentSearchHistory = null;
      window.localStorage.removeItem("searchHistory");
      this.recentSearchesTriggered = false;
      this.hideModal();
    },
    deleteSearch(searchVal) {
      let searchHistory = window.localStorage.getItem("searchHistory");
      searchHistory = JSON.parse(searchHistory);
      searchHistory.find((search, i) => {
        if (search.searchValue === searchVal) {
          searchHistory.splice(i, 1);
        }
        if (JSON.stringify(searchHistory) == "[]") {
          window.localStorage.setItem("searchHistory", "");
          this.recentSearchHistory = "";
        } else {
          window.localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
          this.recentSearchHistory = window.localStorage.getItem("searchHistory") ? this.sortSearchHistory(JSON.parse(window.localStorage.getItem("searchHistory"))) : "";
        }
      });
    },
    expandSection(section) {
      if (this.autocompleteSectionExpanded === section) {
        this.autocompleteSectionExpanded = null;
        this.autocompleteExpandedClicked = false;
      } else {
        this.autocompleteSectionExpanded = section;
        this.autocompleteExpandedClicked = true;
        authHeader().then((headers) => {
          this.autocompleteInFlight = true;
          const bounds = this.$store.state.mapBoundsArray;
          const boundsVal =
              bounds[0][1] + "," +
              bounds[0][0] + "," +
              bounds[1][1] + "," +
              bounds[1][0];
          const params = {
            query: this.currentAutocompleteValue,
            bounds: boundsVal,
            restrict_match_type: section,
            results_per_page: 100,
            page: 1,
          };
          axios
            .get(
              process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/map/v4/autocomplete",
              {
                headers: headers,
                params: params,
              },
            )
            .then((results) => {
              this.autocompleteInFlight = false;
              this.matchType = results.data["match_type_with_mindist"];
              this.autocompleteExpandedResults = results.data[section + "_results"]["results"];
              if (results.data[section + "_results"].length > 0) {
                // this.autocompleteExpandedResults = results.data["results"];
              }
              this.$refs.loadMore.addEventListener("scroll", (e) => {
                if (this.$refs.loadMore.scrollTop + this.$refs.loadMore.clientHeight >= this.$refs.loadMore.scrollHeight) {
                  this.loadMore();
                }
              });
            });
        });
      }
    },
    hideModal() {
      this.$refs["hideModal"].hide();
    },
    loadMore() {
      this.autocompleteExpandedPage++;
      this.autocompleteInFlight = true;
      const bounds = this.$store.state.mapBoundsArray;
      const boundsVal =
          bounds[0][1] + "," +
          bounds[0][0] + "," +
          bounds[1][1] + "," +
          bounds[1][0];
      const params = {
        query: this.currentAutocompleteValue,
        bounds: boundsVal,
        restrict_match_type: this.autocompleteSectionExpanded,
        results_per_page: 100,
        page: this.autocompleteExpandedPage,
      };
      authHeader().then((headers) => {
        axios
          .get(
            process.env.VUE_APP_SERVICE_BASE_URL + "/rest/landglide/map/v4/search",
            {
              headers: headers,
              params: params,
            },
          )
          .then((results) => {
            this.autocompleteInFlight = false;
            this.matchType = results.data["match_type_with_mindist"];
            const oldResults = this.autocompleteExpandedResults;
            const newResults = results.data[this.autocompleteSectionExpanded + "_results"]["results"];
            newResults.forEach(function(result) {
              oldResults.push(result);
            });
            this.autocompleteExpandedResults = oldResults;
            if (results.data[this.autocompleteSectionExpanded + "_results"].length < 100) {
              this.$refs.loadMore.removeEventListener("scroll", (e) => {
                if (this.$refs.loadMore.scrollTop + this.$refs.loadMore.clientHeight >= this.$refs.loadMore.scrollHeight) {
                  this.loadMore();
                }
              });
            }
          });
      });
    },
    // credit to https://medium.com/swlh/filtering-sorting-and-searching-in-arrays-with-vue-js-f60951c040fc
    sortSearchHistory(Arr) {
      if (Arr === null) {
        return Arr;
      } else {
        Arr = Arr.sort((a, b) => {
          return b.time - a.time;
        });
        return Arr;
      }
    },
  },
};
</script>

<style lang="scss"></style>
